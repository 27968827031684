import { forwardRef, PropsWithChildren, useImperativeHandle, useRef, useState } from 'react';

// types
import { DropdownProps } from './Dropdown.types';

// components
import { InnerHtml } from '../InnerHtml/inner-html';

// utils
import { decodingContent } from 'utils/decodingContent';
import { classNameBuilder } from 'utils/classNameBuilder';
import { useOnClickOutside } from 'utils/use-on-click-outside';

function DropdownWithoutRef(
  {
    children,
    label,
    placeholder,
    items = [],
    handleDropdownClick,
    showArrowLeft = false,
    showLabelAsItem = true,
    showAll = false,
    useCheckboxes = false,
    className,
    textBeforePlaceholder,
    allLabel,
  }: Readonly<PropsWithChildren<DropdownProps>>,
  ref,
) {
  const compRef = useRef<HTMLDivElement>(null);
  const [selectedItemId, setSelectedItemId] = useState<string | undefined>(undefined);
  const [selectedItemsId, setSelectedItemsId] = useState<any[]>([]);
  const [dropdownActive, setDropdownActive] = useState<boolean>(false);
  const activeClass = dropdownActive ? 'dropdown--active' : '';
  const showArrowLeftClass = showArrowLeft ? 'show-arrow-left' : '';
  const triggerButtonRef = useRef(null);
  const contentRef = useRef(null);

  const allItem = { id: 'all', value: 'all' };

  useOnClickOutside(compRef, () => {
    setDropdownActive(false);
  });

  useImperativeHandle(ref, () => ({
    setDropdownActive(value: boolean) {
      setDropdownActive(value);
    },
  }));

  return (
    <>
      <div ref={compRef} className={classNameBuilder('dropdown', activeClass, className)}>
        <button
          className={classNameBuilder('dropdown__trigger', showArrowLeftClass)}
          onClick={(e) => {
            e.preventDefault();
            setDropdownActive(!dropdownActive);
          }}
          ref={triggerButtonRef}
        >
          {showArrowLeft && (
            <i className="icon web20-icon web20-icon-chevron-large-down arrow-left" />
          )}
          <span>
            {textBeforePlaceholder && <span>{textBeforePlaceholder} </span>}
            <InnerHtml as="span" content={placeholder} />
          </span>
        </button>
        {dropdownActive && (
          <div className="dropdown__content" ref={contentRef}>
            {children ? (
              <>{children}</>
            ) : (
              <ul>
                {showLabelAsItem && !useCheckboxes && (
                  <li>
                    <a
                      href={'#'}
                      onClick={(e) => {
                        e.preventDefault();
                        setSelectedItemId(undefined);
                        setDropdownActive(!dropdownActive);
                        if (handleDropdownClick) {
                          handleDropdownClick(undefined, e);
                        }
                      }}
                    >
                      <InnerHtml as="span" content={label} />
                    </a>
                  </li>
                )}
                {showAll && useCheckboxes && (
                  <li>
                    <label>
                      <input
                        type="checkbox"
                        value={allItem.id}
                        checked={selectedItemsId.includes(allItem.id)}
                        onChange={(e) => {
                          const checked = e.target.checked;
                          if (checked) {
                            if (!selectedItemsId.includes(allItem.id)) {
                              setSelectedItemsId([allItem.id]);
                            }
                          } else {
                            setSelectedItemsId([]);
                          }
                          setDropdownActive(!dropdownActive);
                          if (handleDropdownClick) {
                            handleDropdownClick(undefined, e);
                          }
                        }}
                      />
                      <span>{decodingContent(allLabel)}</span>
                    </label>
                  </li>
                )}
                {items.map((item) => (
                  <li
                    key={item.id}
                    className={
                      item.id === selectedItemId ? 'dropdown__content--selected' : undefined
                    }
                  >
                    {useCheckboxes ? (
                      <label>
                        <input
                          type="checkbox"
                          value={item.id}
                          checked={selectedItemsId.includes(item.id)}
                          onChange={(e) => {
                            const checked = e.target.checked;
                            let itemIds = selectedItemsId;
                            if (showAll) {
                              itemIds = itemIds.filter((id) => id !== allItem.id);
                            }
                            if (checked) {
                              if (!itemIds.includes(item.id)) {
                                setSelectedItemsId([...itemIds, item.id]);
                              }
                            } else {
                              setSelectedItemsId(itemIds.filter((id) => id !== item.id));
                            }
                            setDropdownActive(!dropdownActive);
                            if (handleDropdownClick) {
                              handleDropdownClick(item, e);
                            }
                          }}
                        />
                        <span>{decodingContent(item.name)}</span>
                      </label>
                    ) : (
                      <a
                        href={`#${item.id}`}
                        onClick={(e) => {
                          e.preventDefault();
                          setSelectedItemId(item.id);
                          setDropdownActive(!dropdownActive);
                          if (handleDropdownClick) {
                            handleDropdownClick(item, e);
                          }
                        }}
                      >
                        {decodingContent(item.name)}
                      </a>
                    )}
                  </li>
                ))}
              </ul>
            )}
          </div>
        )}
      </div>
    </>
  );
}

export const Dropdown = forwardRef(DropdownWithoutRef);
