const set = new Set();

export const queue = {
  add: (key: string) => {
    set.add(key);
  },
  has: (key: string) => {
    return set.has(key);
  },
  process: (key: string) => {
    set.delete(key);
  },
};
